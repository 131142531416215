import { ReactElement, ReactNode } from 'react'
import BigNumber from 'bignumber.js'

import { ICurrency } from 'store/fetchers/currencies'

import { FormattedData } from 'pages/account/wallets/info/wallet.types'

import formatterStyles from './formatter.module.scss'
import { floorNumber } from './floor-number'

export const dateFormatter = new Intl.DateTimeFormat('ru', {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}).format

export const noAmountPlug = ''

export type TBNArg = string | number | BigNumber

// https://stackoverflow.com/questions/1685680/how-to-avoid-scientific-notation-for-large-numbers-in-javascript/1685917
export function noScientific(value: TBNArg): string {
  let x = new BigNumber(value)
  if (x.toString().indexOf('e') < 0) return x.toString()

  let res: string
  if (x.abs().isLessThan(1)) {
    const e = parseFloat(x.toString().split('e-')[1])
    if (e) {
      x = x.multipliedBy(new BigNumber(10).pow(e - 1))
      res = `0.${new Array(e).join('0') + x.toString().substring(2)}`
    }
  } else {
    let e = parseInt(x.toString().split('+')[1])
    if (e > 20) {
      e -= 20
      x = x.dividedBy(new BigNumber(10).pow(e))
      res = `${x.toString()}${new Array(e + 1).join('0')}`
    }
  }

  return res
}

export const toFixed = (value: TBNArg, precision: TBNArg, roundingMode: BigNumber.RoundingMode = 0): string => {
  return new BigNumber(precision).isNaN() || new BigNumber(value).isNaN()
    ? noAmountPlug
    : noScientific(
        new BigNumber(new BigNumber(value).toFixed(new BigNumber(precision).toNumber(), roundingMode)).decimalPlaces(
          new BigNumber(precision).toNumber(),
        ),
      )
}

export const addSpacesInNumber = (number): string => {
  // check whether 'number' is a pure string
  if (number !== '0' && !+number) {
    return noAmountPlug
  }

  const num = number.toString()

  // check whether 'number' is fractional
  if (num.includes('.')) {
    const dotIndex = num.indexOf('.')

    return (+num.slice(0, dotIndex)).toLocaleString('ru-RU') + num.slice(dotIndex)
  }

  return (+num).toLocaleString('ru-RU')
}

export const formatCurrency = (coin: ICurrency, isAlliesDisabled = false): ReactElement => {
  if (!coin?.code) return null
  let displayableCoinCode = coin.display_code ?? coin.code

  if (coin.code.length > 6) {
    displayableCoinCode = coin.code.slice(0, 5)
    displayableCoinCode += '...'
  }

  return (
    <p className={formatterStyles.coin}>
      {displayableCoinCode}
      {isAlliesDisabled === false && (coin.display_network || coin.network !== coin.code) && (
        <span className={formatterStyles.base}>&nbsp;{coin.display_network || coin.network}</span>
      )}
    </p>
  )
}

export const formatAmount = (
  value: TBNArg,
  currency: ICurrency,
  precision: number,
  isAlliesDisabled?: boolean,
): ReactNode => (
  <>
    {addSpacesInNumber(floorNumber(+value, precision))} {formatCurrency(currency, isAlliesDisabled)}
  </>
)

export const formatRate = (
  value: TBNArg,
  from: ICurrency,
  to: ICurrency,
  precision: number,
  isAlliesDisabled?: boolean,
): ReactNode => (
  <>
    {addSpacesInNumber(toFixed(value, precision))}
    <span>
      {' '}
      {formatCurrency(from, isAlliesDisabled)}/{formatCurrency(to, isAlliesDisabled)}
    </span>
  </>
)

export const formatValue = (value: TBNArg, precision: number, rm?: BigNumber.RoundingMode): string => {
  const roundedValue = new BigNumber(toFixed(value, precision, rm))
  if (roundedValue.isNaN()) return noAmountPlug
  if (roundedValue.isLessThan(1000)) return roundedValue.toString()

  const formatter = new Intl.NumberFormat('en-US', {})

  return formatter.format(roundedValue.toNumber()).replace(/,/g, ' ')
}

export const formatHash = (hash): string => {
  return hash.substring(0, 6) + '...' + hash.substring(hash.length - 6, hash.length)
}

export const formatLargeValue = (amount: TBNArg, decimals?: TBNArg, isAddSpaces?: boolean): string => {
  let stringAmount = decimals ? toFixed(amount, decimals).toString() : amount?.toString() || ''

  if (stringAmount === noAmountPlug) return stringAmount

  const intPart = stringAmount.replaceAll(' ', '').split('.')[0].replace(/\s/g, '')

  const [, decimalPart] = stringAmount.replaceAll(' ', '').split('.')

  // Format only the integer part with spaces if it looks like '123456'
  if (/^\d{4,6}$/.test(intPart)) {
    const formattedIntPart = addSpacesInNumber(intPart)
    return decimalPart ? `${formattedIntPart}.${decimalPart}` : formattedIntPart
  }

  const parsedValue = new BigNumber(stringAmount)

  // Check if the number is a decimal
  if (decimalPart && /\d+e[+-]\d+/i.test(stringAmount)) {
    // Round the parsed value to the nearest integer
    const roundedValue = parsedValue.integerValue(BigNumber.ROUND_HALF_UP)

    // Update the amount with the rounded value
    amount = roundedValue
  }

  stringAmount = decimals ? toFixed(amount, decimals).toString() : amount?.toString() || ''

  // value > 1 000 000 000 => 1 B
  if (intPart.length > 9) {
    return `${addSpacesInNumber(new BigNumber(intPart.slice(0, -7)).dividedBy(100).toFormat())}B`
  }

  // value > 1 000 000 => 1 M
  if (intPart.length > 6) {
    return `${addSpacesInNumber(new BigNumber(intPart.slice(0, -4)).dividedBy(100).toFormat())}M`
  }

  // cut fractional part to make max value length - 10 (+1 dot sign)
  if (stringAmount.replace(/\s/g, '').length > 11) {
    return addSpacesInNumber(
      new BigNumber(stringAmount.replace(/\s/g, '')).toFormat(10 - intPart.length, BigNumber.ROUND_DOWN, {
        decimalSeparator: '.',
        groupSeparator: ' ',
      }),
    )
  }

  return isAddSpaces ? addSpacesInNumber(stringAmount) : stringAmount
}

export const getGraphValuesByPeriod = (arr: FormattedData[], arrLength: number): FormattedData[] => {
  const length = arr.length
  const step = Math.ceil(length / (arrLength - 1))

  if (length <= arrLength - 1) {
    return arr
  }

  const selectedValues = []

  for (let i = 0; i < length; i += step) {
    selectedValues.push(arr[i])
  }

  selectedValues.push(arr[length - 1])

  return selectedValues.slice(0, arrLength)
}

export const getGraphTickCountByPeriod = (period: string) => {
  switch (period) {
    case '12H':
      return 6
    case '1D':
      return 8
    case '3D':
      return 9
    case '1W':
      return 7
    case '1M':
      return 9
    default:
      return 6
  }
}

export const formatPrice = (rate) => {
  const roundedNum = parseFloat(rate.toFixed(7))
  const strNum = rate.toString()

  if (rate < 1 && strNum.length - strNum.indexOf('.') >= 8) {
    const decimalPart = strNum.split('.')[1]?.slice(-4)
    return `0.0...${decimalPart}`
  }

  if (rate >= 1 && strNum.split('.')[1]?.length > 7) {
    if (strNum.length > 8) {
      return addSpacesInNumber(Math.round(rate))
    }
    return addSpacesInNumber(roundedNum)
  }

  return addSpacesInNumber(rate)
}

export function formatNumber(number: string | number): string | null {
  if (number === undefined || number === null) return null

  const stringValue = typeof number === 'number' ? number.toString() : number

  const parts = stringValue.split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  return parts.join('.')
}
