import { forwardRef, InputHTMLAttributes } from 'react'

import styles from './styles.module.scss'

type FWType = InputHTMLAttributes<HTMLInputElement> & { onEnter?: () => void }

export default forwardRef<HTMLInputElement, FWType>(function Input({ onEnter, ...props }, ref) {
  const handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 13: {
        event.stopPropagation()
        event.preventDefault()

        if (onEnter) {
          onEnter()
        }

        break
      }
    }
  }
  return <input className={styles.input} onKeyDown={handleKeyDown} ref={ref} {...props} />
})
