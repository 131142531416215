export default function onlyNumberChanger(value: string, changer: (value: string) => void) {
  let val = value
  // replace , -> .
  if (val[val.length - 1] === ',') val = val.slice(0, -1) + '.'

  // check string
  if (val && val.search(/^(\d+|\.\d+|\d+\.\d+|\d+\.)(\d+)?\s*$/gi) === -1) {
    return
  }
  changer(val)
}
